import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import ChooseTypeSection from '@sections/link_card_bank/choose_type';
import Button from '@components/common/button';
import LinkCardSection from '@sections/link_card_bank/link_card';
import LinkBankSection from '@sections/link_card_bank/link_bank';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useQueryParams } from '@hooks/helpers/useQueryParams';

const LinkCardBankLayout = () => {
    const [type, setType] = useState<'card' | 'bank'>();
    const [preventType, setPreventType] = useState<'card' | 'bank'>('card');
    const [loginId, accountId, linkedType] = useQueryParams(['loginId', 'accountId', 'type']);
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank'
    });

    useEffect(() => {
        if (loginId && accountId) {
            setType('bank');
        }
    }, [loginId, accountId]);

    useEffect(() => {
        if (linkedType) {
            setType(linkedType as 'card');
            setPreventType(linkedType as 'card');
        }
    }, [linkedType]);

    const handleChoseOtherType = () => {
        setType(undefined);
    };
    const isCard = useMemo(() => type === 'card', [type]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">
                {!type ? t('title') : isCard ? t('link_card') : t('link_bank')}
            </Typography>
            <div className={styles.content}>
                {!type && (
                    <ChooseTypeSection
                        type={preventType}
                        onChange={(value) => setPreventType(value)}
                    />
                )}
                {type &&
                    (isCard ? (
                        <LinkCardSection
                            handleChoseOtherType={() => {
                                setPreventType('bank');
                                setType('bank');
                            }}
                        />
                    ) : (
                        <LinkBankSection
                            handleChoseOtherType={() => {
                                setPreventType('card');
                                setType('card');
                            }}
                        />
                    ))}
                {!type && (
                    <Button variant="primary" size="normal" onClick={() => setType(preventType)}>
                        Next
                    </Button>
                )}
            </div>
        </motion.div>
    );
};

export default LinkCardBankLayout;
