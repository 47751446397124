import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';

export const useHelpCenterModals = () => {
    const { onOpen, onClose } = useModalContext();
    const handleOpenSubmitRequestModal = () => {
        onOpen({
            modalId: modalIds.SUBMIT_HELP_REQUEST,
            onSubmit: onClose
        });
    };

    return {
        handleOpenSubmitRequestModal
    };
};
