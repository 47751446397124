import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const PrivacyPolicyLayout = () => {
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h2">Privacy Policy</Typography>
            <div className={styles.content}>
                <div>
                    At XEPPT Inc. (XEPPT), we respect the privacy rights of individuals and are
                    committed to keeping personal information accurate, confidential, and secure.
                    This Privacy Policy outlines how we collect, use, disclose, and protect your
                    personal information.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    INTRODUCTION
                </Typography>
                <div>
                    XEPPT provides a variety of products and services to the public, including but
                    not limited to the processing of payments in connection with the use of debit
                    cards and credit cards. XEPPT is both a vendor of processing equipment and a
                    processor of payments for merchants who have purchased and are using our
                    processing equipment.
                </div>
                <div>
                    In the course of providing these products and services to merchants (e.g.
                    retailers), XEPPT may collect, with consent, certain personal information about
                    its merchants. In addition, XEPPT may receive personal information from
                    customers of merchants while providing XEPPT’s payment processing services.
                    XEPPT is responsible for personal information that it collects, uses, or
                    discloses as well as personal information that it receives from the customers of
                    its merchants.
                </div>
                <div>
                    “Personal Information” means information about an identifiable individual. This
                    may include, without limitation, the individual’s name, home address, age,
                    income, health, or financial information. Personal Information does not include
                    the name, title, business address or telephone number of an employee of an
                    organization.
                </div>
                <div>
                    The XEPPT Privacy Policy is based on Canada’s Personal Information Protection
                    and Electronic Documents Act (“PIPEDA”).
                </div>
                <div>
                    By submitting your Personal Information to XEPPT, you signify your consent to
                    the collection, use and disclosure of your Personal Information in accordance
                    with this Policy. If you do not agree with the terms of this Privacy Policy,
                    please do not provide any Personal Information to XEPPT.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    PERSONAL INFORMATION WE COLLECT
                </Typography>
                <div>
                    XEPPT collects personal information in the course of establishing a business
                    relationship, setting up a user’s account, providing our products and services
                    (including but not limited to requesting our XEPPT reloadable Visa Prepaid
                    Card), and processing payments through the XEPPT wallet or Card.
                </div>
                <div>
                    Information is collected with consent, either directly from customers,
                    merchants, or from customers of merchants, and may include your full name, civic
                    address, billing address, email, occupation, sex, gender, date of birth, and
                    financial details like bank account, Interac email address, and debit/credit
                    card information.
                </div>
                <div>
                    AWe also collect information for identity verification and to comply with legal
                    and regulatory requirements, including collecting identity reference numbers,
                    expiry dates, and places of issuance.
                </div>
                <div>
                    When you visit our website, we may automatically collect information such as IP
                    addresses, device IDs, browser types, operating systems, and details about your
                    visit, including pages viewed and the date/time of access.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    WHY WE COLLECT YOUR PERSONAL INFORMATION
                </Typography>
                <div>
                    XEPPT collects personal information to establish and maintain business
                    relationships, provide our products and services effectively, and ensure
                    compliance with legal and regulatory obligations. Specifically, personal
                    information is collected to:
                </div>
                <ol>
                    <li>
                        Process payments, manage accounts, and handle financial operations like
                        loading or reloading the XEPPT Card.
                    </li>
                    <li>
                        Meet legal obligations, including those of banking partners, PIPEDA, and AML
                        requirements such as identity verification and record-keeping.
                    </li>
                    <li>
                        Verify identities to prevent fraud and ensure the security of transactions.
                    </li>
                    <li>
                        Communicate with customers, provide support, and offer additional products
                        or services, marketing notices, and promotional offers (with consent).
                    </li>
                    <li>
                        Improve website functionality, troubleshoot service issues, analyze visitor
                        data, and optimize services.
                    </li>
                </ol>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    HOW WE COLLECT YOUR INFORMATION
                </Typography>
                <div>
                    XEPPT may collect personal information directly, from third parties, or
                    automatically when you visit our digital platforms. Information may be collected
                    through the following channels:
                </div>
                <ol>
                    <li>
                        Direct Interaction: During account creation, transactions, or service
                        requests.
                    </li>
                    <li>
                        Automated Collection: When you visit our website, app, using cookies and
                        similar technologies.
                    </li>
                    <li>
                        Third-Party Sources: From partners or vendors for identity verification and
                        fraud prevention, including credit bureaus and government institutions.
                    </li>
                    <li>
                        Public Sources: Including directories, public records, and government
                        registries.
                    </li>
                </ol>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    HOW YOUR INFORMATION IS USED
                </Typography>
                <div>
                    XEPPT uses the personal information collected to provide, maintain, and enhance
                    our products and services. Specifically, at XEPPT we may use Personal
                    Information to carry out one or more of the following:
                </div>
                <ul>
                    <li>To communicate with customers to provide our products and services.</li>
                    <li>
                        To assist in the development and marketing of our products and services
                        (with consent).
                    </li>
                    <li>
                        To conduct credit investigations and collect financial information from
                        credit reporting agencies.
                    </li>
                    <li>
                        To forward to financial institutions and debit and credit card associations
                        (including Visa, Mastercard and Interac).
                    </li>
                    <li>
                        To establish and maintain relations with customers, suppliers, financial
                        institutions, and affiliates, and to provide ongoing products and services,
                        administer accounts, make, and receive payments, engage in leasing
                        arrangements, and fulfill contractual obligations.
                    </li>
                    <li>
                        To develop, enhance, market, sell, provide, and inform our customers of
                        products and services of third parties, including our affiliates with whom
                        XEPPT has a commercial relationship.
                    </li>
                    <li>
                        To update and verify our databases and information provided by third
                        parties.
                    </li>
                    <li>
                        To engage in business transactions, including without limitation, the
                        reorganization, purchase, sale, lease, merger, amalgamation or any other
                        type of acquisition, disposition, securitization, or financing involving
                        XEPPT or its affiliates.
                    </li>
                </ul>
                <div>
                    Your personal information is used solely for the purposes for which it was
                    collected. If we wish to use the information for any new purpose, we will obtain
                    your consent before proceeding.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    HOW YOUR INFORMATION IS SHARED
                </Typography>
                <div>
                    Personal information is only shared for the purposes for which it was collected
                    unless additional consent is obtained. We may share your information with XEPPT
                    employees, affiliates, or third-party service providers to deliver our services
                    effectively or during a sale of the business. For example, we may share
                    financial information with our banking partner, Digital Commerce Bank, to
                    process transactions; with Flinks, to connect your bank account to our XEPPT
                    wallet; and with Shufti Pro, to authenticate your identity.
                </div>
                <div>
                    We may also be required to share information by law, such as in response to a
                    production order.
                </div>
                <div>
                    Data may be shared with third-party service providers who store information in
                    jurisdictions outside of Canada, such as in the United States. In such
                    jurisdictions, government authorities may have lawful access to personal
                    information without prior notice to you.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    HOW YOUR INFORMATION IS PROTECTED
                </Typography>
                <div>
                    XEPPT takes the security of personal information seriously by implementing a
                    comprehensive range of technological, physical, and organizational safeguards.
                    These measures are designed to protect data from unauthorized access, loss, or
                    theft. Depending on the nature and sensitivity of the information, data may be
                    stored on XEPPT’s servers, within our computer systems, or in secure facilities
                    operated by trusted third-party service providers.
                </div>
                <div>
                    Appropriate technological controls, such as passwords, encryption, and
                    firewalls, are in place on our computer systems and data processing procedures.
                    Physical controls, such as locked filing cabinets, restricted access to offices,
                    and alarm systems, are also implemented, along with organizational controls,
                    including staff training and access restrictions based on a "need-to-know"
                    basis.
                </div>
                <div>
                    Please note that our service providers may be located in various countries, and
                    authorized officials of those governments may lawfully access your personal
                    information without your knowledge or consent under the laws of those countries.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    OUR WEBSITE SECURITY
                </Typography>
                <div>
                    The XEPPT website may contain links to other websites, including those of its
                    business partners. XEPPT is in no way responsible and cannot guarantee the
                    content or privacy of other sites linked to our website.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    CHILDREN’S PRIVACY
                </Typography>
                <div>
                    XEPPT does not provide services to individuals under the age of 16, regardless
                    of parental consent or involvement. We do not store any information related to
                    individuals under 16. If we inadvertently receive information from a visitor
                    under the age of 16, we will delete the information from our records.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    WITHDRAWAL OF CONSENT
                </Typography>
                <div>
                    You have the right to withdraw your consent to the collection, use, or
                    disclosure of your personal information at any time, subject to legal or
                    contractual restrictions and reasonable notice. If you choose to withdraw your
                    consent, we will inform you of the implications, which may include our inability
                    to provide certain services.
                </div>
                <Typography className={styles.subtitle_left} weight="semibold" variant="h4">
                    CONTACT US
                </Typography>
                <div>
                    The CPO is ultimately responsible for Personal Information under the control of
                    XEPPT and is accountable for compliance with the terms and procedures of this
                    Privacy Policy.
                </div>
                <div>
                    If you have any questions, concerns, or complaints about your personal
                    information, our privacy practices, or if you wish to access or amend your
                    personal information, please contact our Chief Privacy Officer. XEPPT is
                    committed to addressing all requests and complaints efficiently, typically
                    within 30 days. If we cannot provide access to your information, we will explain
                    the reasons, except where prohibited by law.
                </div>
                <div>
                    XEPPT Inc. <br />
                    3-155 Edward Street <br />
                    Aurora, ON L4G 1W3 <br />
                    Email: privacy@xeppt.com
                </div>
                <Typography className={styles.subtitle_left} weight="semibold" variant="h4">
                    CHANGES TO THIS PRIVACY POLICY
                </Typography>
                <div>
                    We may update this Privacy Policy from time to time. Any changes will be posted
                    on our website at www.xeppt.com/privacy-policy and will be made available upon
                    request through our CPO. We encourage you to review this policy regularly to
                    stay informed about how we are protecting your information.
                </div>
                <Typography className={styles.subtitle_left} weight="semibold" variant="h4">
                    Last Updated: August 29, 2024
                </Typography>
            </div>
        </motion.div>
    );
};

export default PrivacyPolicyLayout;
