import React, { useState } from 'react';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import Info from '@components/common/info';
import { Icon } from '@components/icons';
import Chat from '@components/chat';
import Typography from '@components/common/typography';
import onlineIllustration from '@svg/illustrations/chat_online.svg';
import offlineIllustration from '@svg/illustrations/chat_offline.svg.svg';
import Chats from '@sections/global/support_chat/chats';

const SupportChat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    return (
        <div className={styles.wrapper}>
            <Info variant="left" content="Contact support">
                <Button
                    onClick={() => setIsOpen((state) => !state)}
                    className={styles.main_button}
                    leftIcon={isOpen ? 'close' : 'chat'}
                    variant="icon-dark"
                />
            </Info>
            {isOpen && (
                <div className={styles.chat_wrapper}>
                    <div className={styles.header}>
                        <button
                            className={styles.back}
                            onClick={() => setIsChatOpen((state) => !state)}>
                            <Icon name="nav_left" />
                        </button>
                        <div className={styles.header_text}>
                            <Icon name="headset" />
                            <p>XEPPT Support</p>
                        </div>
                    </div>
                    {!isChatOpen ? (
                        <Chats />
                    ) : (
                        <>
                            <div className={styles.empty_wrapper}>
                                <img src={onlineIllustration} alt="" />
                                <Typography variant="body3">
                                    We're online! <br />
                                    How can we help?
                                </Typography>
                            </div>
                            <Chat
                                withoutInputBorder
                                isShowReceiver={false}
                                className={styles.chat_content}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SupportChat;
