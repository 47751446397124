import React, { FC } from 'react';
import { TBankAccount, TEPSCard } from '@xeppt/xeppt-sdk/types';
import styles from '@sections/manage_accounts/interac/styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';

interface IProps {
    card: TEPSCard;
}

const CardSection: FC<IProps> = ({ card }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'manage_accounts.bank'
    });
    return (
        <div className={styles.wrapper}>
            <div className={styles.row}>
                <Typography variant="body2" weight="bold">
                    {t('card_holder')}
                </Typography>
                <Typography variant="body2">{card.cardHolderName}</Typography>
            </div>
            <div className={styles.row}>
                <Typography variant="body2" weight="bold">
                    {t('card_brand')}
                </Typography>
                <Typography variant="body2">{card.cardBrand}</Typography>
            </div>
            <div className={styles.row}>
                <Typography variant="body2" weight="bold">
                    {t('card_number')}
                </Typography>
                <Typography variant="body2">{card.cardNumber}</Typography>
            </div>
        </div>
    );
};

export default CardSection;
