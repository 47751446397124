import React from 'react';
import Typography from '@components/common/typography';
import Select from '@components/common/select';
import Textarea from '@components/common/textarea';
import styles from './styles.module.scss';
import Uploader from '@components/uploader';

const SubmitRequestModal = () => {
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.header}>
                Submit a request
            </Typography>
            <div className={styles.content}>
                <Select
                    items={[]}
                    onChange={() => {}}
                    label="Subject"
                    full
                    placeholder="Select the subject of your request"
                />
                <Textarea
                    full
                    label="Description"
                    placeholder="Please describe your issue here..."
                />
                <Uploader />
            </div>
        </div>
    );
};

export default SubmitRequestModal;
