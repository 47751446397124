import React, { FC, useEffect, useMemo } from 'react';
import Header from './header';
import styles from './styles.module.scss';
import planetImage from '@svg/planet.svg';
import classNames from 'classnames/bind';
import { Outlet, useLocation } from 'react-router-dom';
import ActivityServiceProvider from '../../service_providers/activity_provider';
import { NotificationsContextProvider } from '@context/notifications_context';
import { useGlobalProvider } from '@hooks/helpers/useGlobalProvider';
import SupportChat from '@sections/global/support_chat';

interface IProps {
    className?: string;
}

const cx = classNames.bind(styles);
const GlobalProvider: FC<IProps> = ({ className }) => {
    const location = useLocation();
    const rootStyles = useMemo(() => cx([styles.wrapper, className]), [className]);
    useGlobalProvider();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <ActivityServiceProvider>
            <NotificationsContextProvider>
                <div className={rootStyles}>
                    <Header />
                    <Outlet />
                    <div className={styles.planet}>
                        <img src={planetImage} alt="planet illustration" />
                    </div>
                    <SupportChat />
                </div>
            </NotificationsContextProvider>
        </ActivityServiceProvider>
    );
};

export default GlobalProvider;
