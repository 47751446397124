import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { ESendRequestMoneyMethod } from '@enum';

interface IProps {
    onClick?: () => void;
    checked?: boolean;
    type: ESendRequestMoneyMethod;
}

const cx = classNames.bind(styles);

const ChooseMethod: FC<IProps> = ({ onClick, type, checked }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.method'
    });
    const rootStyles = useMemo(() => cx([styles.wrapper, { isChecked: checked }]), [checked]);

    const renderSendMessage = () => {
        switch (type) {
            case ESendRequestMoneyMethod.INTERNAL:
                return t('internal.description');
            case ESendRequestMoneyMethod.E_TRANSFER:
                return t('e_transfer.description');
            case ESendRequestMoneyMethod.BANK:
                return t('bank.description');
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                return t('e_transfer_request.description');
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return t('internal_request.description');
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return t('bank_request.description');
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return t('eps_request.description');
        }
    };

    const renderTitle = () => {
        switch (type) {
            case ESendRequestMoneyMethod.INTERNAL:
                return t('internal.title');
            case ESendRequestMoneyMethod.E_TRANSFER:
                return t('e_transfer.title');
            case ESendRequestMoneyMethod.BANK:
                return t('bank.title');
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                return t('e_transfer_request.title');
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return t('internal_request.title');
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return t('bank_request.title');
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return t('eps_request.title');
        }
    };

    const getIcon = () => {
        switch (type) {
            case ESendRequestMoneyMethod.INTERNAL:
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return 'account_balance';
            case ESendRequestMoneyMethod.E_TRANSFER:
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                return 'interac';
            case ESendRequestMoneyMethod.BANK:
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return 'bank';
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return 'credit_card';
        }
    };

    return (
        <button
            className={rootStyles}
            onClick={() => {
                if (onClick) onClick();
            }}>
            <div className={styles.left}>
                <div className={styles.icon}>
                    <Icon name={getIcon()} />
                </div>
                <div className={styles.text_wrapper}>
                    <Typography variant="body1" weight="semibold">
                        {renderTitle()}
                    </Typography>
                    <Typography variant="body3">{renderSendMessage()}</Typography>
                </div>
            </div>
            <Radio checked={checked} />
        </button>
    );
};

export default ChooseMethod;
