import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import GlobalProvider from './UI/layout_providers/global_provider';
import AuthProvider from './UI/layout_providers/auth_provider';
import SignUpLayout from '@layouts/signup';
import SignInLayout from '@layouts/signin';
import TransactionsLayout from '@layouts/transactions';
import SettingsLayout from '@layouts/settings';
import ProfileLayout from '@layouts/settings/profile';
import ModalContextProvider from './context/modal_context';
import LinkCardBankLayout from '@layouts/link_card_bank';
import { routes } from '@const/routes';
import XepptCardsLayout from '@layouts/xeppt_cards';
import DashboardLayout from '@layouts/dashboard';
import XepptCardLayout from '@layouts/xeppt_cards/card';
import OrderCardLayout from '@layouts/xeppt_cards/order_card';
import TransactionDetailsLayout from '@layouts/transactions/transacrtion_details';
import BusinessLayout from '@layouts/settings/business';
import ForgotPasswordLayout from '@layouts/forgot_password';
import { UserContextProvider } from '@context/user_context';
import SendAndRequestLayout from '@layouts/send_and_request';
import PayBillsLayout from '@layouts/pay_bills';
import ManageAccountsLayout from '@layouts/manage_accounts';
import { EActiveETransfer, EActivePayBills } from '@enum';
import PrivacyPolicyLayout from '@layouts/privacy';
import TermsLayout from '@layouts/terms';
import SettingsNotificationsLayout from '@layouts/settings/notifications';
import NotFoundPage from '@layouts/not_found';
import CashbackLayout from '@layouts/cashback';
import HelpCenterLayout from '@layouts/help_center';
import InstallExtensionLayout from '@layouts/extension/install';
import UninstallExtensionLayout from '@layouts/extension/uninstall';

const authRoutes = [
    { path: routes.signup, element: <SignUpLayout /> },
    { path: routes.signin, element: <SignInLayout /> },
    { path: routes.forgot_password, element: <ForgotPasswordLayout /> },
    { path: routes.privacy_policy, element: <PrivacyPolicyLayout /> },
    { path: routes.terms_of_service, element: <TermsLayout /> },
    { path: routes.extension_install, element: <InstallExtensionLayout /> },
    { path: routes.extension_uninstall, element: <UninstallExtensionLayout /> }
];

const mainRoutes = [
    { path: routes.dashboard, element: <DashboardLayout /> },
    { path: routes.transactions, element: <TransactionsLayout /> },
    { path: routes.transaction_details, element: <TransactionDetailsLayout /> },
    { path: routes.settings, element: <SettingsLayout /> },
    { path: routes.help_center, element: <HelpCenterLayout /> },
    { path: routes.settings_profile, element: <ProfileLayout /> },
    { path: routes.settings_business, element: <BusinessLayout /> },
    { path: routes.cashback_history, element: <CashbackLayout /> },
    { path: routes.settings_notifications, element: <SettingsNotificationsLayout /> },
    { path: routes.link_card_bank, element: <LinkCardBankLayout /> },
    { path: routes.xeppt_cards, element: <XepptCardsLayout /> },
    { path: routes.xeppt_card, element: <XepptCardLayout /> },
    { path: routes.order_xeppt_card, element: <OrderCardLayout /> },
    { path: routes.send_and_request, element: <SendAndRequestLayout /> },
    {
        path: routes.send_and_request_send,
        element: <SendAndRequestLayout activeTab={EActiveETransfer.SEND} />
    },
    {
        path: routes.send_and_request_autodeposit,
        element: <SendAndRequestLayout activeTab={EActiveETransfer.AUTODEPOSIT} />
    },
    {
        path: routes.send_and_request_request,
        element: <SendAndRequestLayout activeTab={EActiveETransfer.REQUEST} />
    },
    {
        path: routes.send_and_request_schedule,
        element: <SendAndRequestLayout activeTab={EActiveETransfer.SCHEDULE_PAYMENTS} />
    },
    {
        path: routes.send_and_request_contacts,
        element: <SendAndRequestLayout activeTab={EActiveETransfer.CONTACTS} />
    },
    { path: routes.pay_bills, element: <PayBillsLayout /> },
    { path: routes.pay_bills_pay, element: <PayBillsLayout activeTab={EActivePayBills.PAY} /> },
    {
        path: routes.pay_bills_payees,
        element: <PayBillsLayout activeTab={EActivePayBills.MANAGE} />
    },
    {
        path: routes.pay_bills_schedule,
        element: <PayBillsLayout activeTab={EActivePayBills.SCHEDULE} />
    },
    { path: routes.manage_accounts, element: <ManageAccountsLayout /> }
];

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<UserContextProvider />}>
            <Route element={<ModalContextProvider />}>
                <Route element={<AuthProvider />}>
                    {authRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Route>
                <Route element={<GlobalProvider />}>
                    {mainRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Route>
        </Route>
    )
);
