import { ENewNotificationType, ENotificationCategory } from '@enum';
import { EEventName } from '@xeppt/xeppt-sdk/types/notification';

export const notificationsByType = {
    [ENewNotificationType.ACCOUNT]: [
        EEventName.EftAccountLinked,
        EEventName.BalanceUpdated,
        EEventName.ETransferAccountUpdated,
        'EPS_CARD_CREATED'
    ],
    [ENewNotificationType.LOGOUT]: [EEventName.PasswordChanged],
    [ENewNotificationType.USER]: [EEventName.UserVerified],
    [ENewNotificationType.CARD]: [
        EEventName.CardBalanceLoaded,
        EEventName.CardCreated,
        EEventName.CardLimitsUpdated,
        EEventName.CardPinChanged,
        EEventName.CardStatusChanged
    ],
    [ENewNotificationType.BILL]: [
        EEventName.SchedulePaymentProcessed,
        EEventName.BillPaymentProcesses
    ],
    [ENewNotificationType.TRANSACTION]: [
        EEventName.PaymentReceived,
        EEventName.MoneyRequestCreated,
        EEventName.PaymentStatusChanged
    ],
    [ENewNotificationType.PHONE]: [EEventName.PrimaryPhoneChanged],
    [ENewNotificationType.EMAIL]: [EEventName.PrimaryEmailChanged]
};

export const notificationsGroups = {
    [ENotificationCategory.CARD]: [
        EEventName.CardBalanceLoaded,
        EEventName.CardCreated,
        EEventName.CardLimitsUpdated,
        EEventName.CardPinChanged,
        EEventName.CardStatusChanged,
        'EPS_CARD_CREATED'
    ],
    [ENotificationCategory.PERSONAL]: [EEventName.PasswordChanged, EEventName.UserVerified],
    [ENotificationCategory.PAYMENTS]: [
        EEventName.PaymentReceived,
        EEventName.MoneyRequestCreated,
        EEventName.PaymentStatusChanged
    ],
    [ENotificationCategory.SCHEDULE]: [
        EEventName.SchedulePaymentProcessed,
        EEventName.BillPaymentProcesses
    ],
    [ENotificationCategory.MARKETING]: []
};
