import React, { useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import RangeDatePicker from '@components/range_date_picker';
import Table from '@components/table';
import styles from './styles.module.scss';
import { formatDateForMoment } from '@utils/date';
import moment from 'moment-timezone';
import illustration from '@svg/illustrations/no_requests.svg';
import { useHelpCenterModals } from '@hooks/modals/useHelpCenterModals';
import ChatDrawer from '@sections/help_center/chat_drawer';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';

const HelpCenterLayout = () => {
    const { handleOpenSubmitRequestModal } = useHelpCenterModals();
    const requests = [];
    const columns = [
        {
            label: 'Subject',
            key: 'subject'
        },
        {
            label: 'ID',
            key: 'id'
        },
        {
            label: 'Created',
            key: 'createdAt',
            isSort: true,
            render: (value: Date) =>
                value && moment(formatDateForMoment(value)).format('MM.DD.YYYY, HH:mm')
        },
        {
            label: 'Updated',
            key: 'updatedAt',
            isSort: true,
            render: (value: Date) =>
                value && moment(formatDateForMoment(value)).format('MM.DD.YYYY, HH:mm')
        },
        {
            label: 'Status',
            key: 'status',
            filterOptions: []
        },
        {
            label: 'Actions',
            key: 'id'
        }
    ];
    const [isOpen, setIsOpen] = useState(false);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <Typography variant="h4">Help Center</Typography>
                <Button variant="primary" size="normal" onClick={handleOpenSubmitRequestModal}>
                    Submit a request
                </Button>
            </div>
            <Typography variant="h5" className={styles.table_header}>
                My requests
            </Typography>
            {requests.length > 0 ? (
                <>
                    <div className={styles.filters}>
                        <Input
                            className={styles.search}
                            full
                            leftIcon="search"
                            placeholder="Search by request ID or subject..."
                        />
                        <RangeDatePicker
                            rootClassName={styles.range}
                            full
                            placeholder="Select a date range created"
                        />
                    </div>
                    <Table columns={columns} isActions emptyDescription="No requests yet" />
                </>
            ) : (
                <div className={styles.empty_wrapper}>
                    <img src={illustration} alt="No requests yet" />
                    <Typography variant="body3">
                        It seems like everything is fine so far... Requests you submit will appear
                        right here
                    </Typography>
                    <Button variant="primary" size="normal" onClick={handleOpenSubmitRequestModal}>
                        Submit a request
                    </Button>
                </div>
            )}
            <ChatDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </motion.div>
    );
};

export default HelpCenterLayout;
