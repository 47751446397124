import React, { FC, useMemo } from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Radio from '@components/common/radio';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { EAccountType } from '@enum';
import { useTranslation } from 'react-i18next';
import Skeleton from '@components/common/skeleton';
import { useUserContext } from '@hooks/context/useUserContext';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    onChange: (value: 'card' | 'bank') => void;
    type: 'card' | 'bank';
}

const cx = classNames.bind(styles);

const ChooseTypeSection: FC<IProps> = ({ type, onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.choose_type'
    });
    const { isDataLoading } = useUserContext();

    const bankButtonStyles = useMemo(
        () => cx([styles.card, { isActive: type === EAccountType.BANK }]),
        [type]
    );

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {isDataLoading ? (
                <Skeleton className={styles.skeleton} />
            ) : (
                <>
                    <button
                        className={cx([styles.card, { isActive: type === EAccountType.CARD }])}
                        onClick={() => onChange('card')}>
                        <div className={styles.left}>
                            <span>
                                <Icon name="credit_card" />
                            </span>
                            <div>
                                <Typography variant="body1" weight="semibold">
                                    {t('credit')}
                                </Typography>
                                <span>{t('accept_credit')}</span>
                            </div>
                        </div>
                        <Radio checked={type === EAccountType.CARD} />
                    </button>
                    <button className={bankButtonStyles} onClick={() => onChange('bank')}>
                        <div className={styles.left}>
                            <span>
                                <Icon name="bank" />
                            </span>
                            <div>
                                <Typography variant="body1" weight="semibold">
                                    {t('bank')}
                                </Typography>
                                <span>{t('accept_bank')}</span>
                            </div>
                        </div>
                        <Radio checked={type === EAccountType.BANK} />
                    </button>
                </>
            )}
        </motion.div>
    );
};

export default ChooseTypeSection;
