import React from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import moment from 'moment-timezone';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { prettifyDate } from '@utils/date';

const Chats = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="h5">My Chats</Typography>
                <div className={styles.chats_wrapper}>
                    <div className={styles.chat_wrapper}>
                        <div className={styles.icon}>
                            <Icon name="headset" />
                        </div>
                        <div className={styles.chat_content}>
                            <div className={styles.chat_header}>
                                <Typography variant="body3">Arlene McCoy</Typography>
                                <div className={styles.date}>
                                    {prettifyDate(new Date(), (val: string) => val)},{' '}
                                    {moment().format('HH:mm')}
                                </div>
                            </div>
                            <Typography variant="body3" className={styles.message}>
                                Hello Peter Morgan! Thanks for providing that information. Based on
                                the error message which you just got
                            </Typography>
                            {/*<div className={styles.counter}>3</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Button variant="primary" size="normal" leftIcon="send">
                    Start new chat
                </Button>
            </div>
        </div>
    );
};

export default Chats;
