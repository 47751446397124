import React, { FC } from 'react';

interface IProps {
    variant?: 'clickable' | 'default';
    label?: string;
}

const Uploader: FC<IProps> = ({ variant = 'default', label }) => {
    return <div>{label && <label>{label}</label>}</div>;
};

export default Uploader;
